import { useLoaderData } from "react-router-dom";
import ReactPlayer from 'react-player'
import { json } from "react-router-dom";

export async function loader({ request }) {
  const url = new URL(request.url);
  const m3u8 = url.searchParams.get("url");
  return json({m3u8})
}

function App() {
  const {m3u8} = useLoaderData()
  return (
    <>
      {
        m3u8 ? <ReactPlayer className="h-screen w-screen" url={m3u8} width="100%" height="100%" controls={true} /> :
          <div className="h-screen w-screen bg-gray-50 flex items-center justify-center"><h1 className="text-3xl text-red-400 font-bold">Hafea 播放器</h1></div>
      }
    </>
      
  );
}

export default App;
